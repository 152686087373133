





































  import { Component, Prop, Vue } from 'vue-property-decorator'
  import { namespace } from 'vuex-class'
  import { BindingHelpers } from 'vuex-class/lib/bindings'
  import { cloneDeep } from 'lodash-es'

  const categories: BindingHelpers = namespace('categories')
  const products: BindingHelpers = namespace('products')

  @Component({
    components: {
      OneCategoryTreeItem: () =>
        import('~/components/organisms/categoryTree/OneCategoryTreeItem.vue'),
    },
  })
  export default class OneCategoryTreeItem extends Vue {
    name: string = 'OneCategoryTreeItem'

    @categories.Action selectCategory: any
    @categories.Getter getCategory: any
    @categories.Getter getSelectedCategoryId: any
    @products.Getter getSortCriteria: any
    @products.Getter getCurrentPage: any
    @products.Getter getSearchedText: any
    @products.Getter getDefaultSortForCatalogBrowse: any
    @products.Getter getPerPage: any
    @products.Mutation('SET_PRICE') setPrice: any

    @Prop({
      required: true,
      type: Array,
      default: () => [],
    })
    selectedCategoriesTree!: Array<string>

    @Prop({
      required: true,
      type: Object,
      default: () => ({}),
    })
    categoriesCount!: Record<string, number>

    @Prop({
      type: String,
      required: true,
    })
    categoryId!: string

    @Prop({
      type: String,
      required: false,
    })
    selectedCategoryId?: string

    @Prop({
      type: Number,
    })
    count?: number

    @Prop({
      type: String,
    })
    currentPriceParameter?: string

    @Prop({
      type: Boolean,
      required: false,
      default: false,
    })
    showEmptyCategories!: boolean

    @Prop({
      type: Boolean,
      default: true,
    })
    useAnchors!: boolean

    @Prop({
      type: Boolean,
      required: false,
      default: false,
    })
    onlySelected!: boolean

    @Prop({
      type: Boolean,
      required: false,
      default: false,
    })
    hydrationIdle!: boolean

    isOpened: boolean = false
    isSearch = !this.showEmptyCategories

    composeHref() {
      const setFiltersQuery = () => {
        if (this.$route?.query?.filtersQuery) {
          return cloneDeep(this.$route.query.filtersQuery)
        }
      }
      const params: any = {
        filtersQuery: setFiltersQuery(),
        page: 1,
        rows: this.getPerPage,
        sortCriteria: this.getSortCriteria,
      }
      if (this.currentPriceParameter) {
        params.price = this.currentPriceParameter
      }
      if (this.isSearch) {
        params.category = this.categoryId
        if (this.getSearchedText) {
          params.q = this.getSearchedText
        }
      }
      const simplifyUrl = () => {
        const defaultRows = 15
        const defaultSortCriteria = this.getDefaultSortForCatalogBrowse
        const { filtersQuery, price, page, rows, sortCriteria } = params
        return (
          !filtersQuery &&
          !price &&
          rows === defaultRows &&
          sortCriteria === defaultSortCriteria &&
          page === 1
        )
      }

      return this.$router.resolve({
        path: this.isSearch
          ? this.$route.path
          : this.$routes.getCategoryPath(this.categoryId) || this.$route.path,
        query: this.isSearch ? params : simplifyUrl() ? null : params,
      }).href
    }

    mounted() {
      this.isOpened = this.isSelected(this.categoryId)
    }

    get shouldShowCategory() {
      return (categoryId: string) => {
        if (this.showEmptyCategories) {
          if (!this.onlySelected) {
            return true
          }
          return this.isSelected(categoryId) || this.hasItems(categoryId)
        }
        return this.hasItems(categoryId)
      }
    }

    get isFolder() {
      return this.category.children.length
    }

    get category() {
      return this.getCategory(this.categoryId)
    }

    get getSelectedCategory() {
      return this.getCategory(this.selectedCategoryId)
    }

    get isSelected() {
      return (categoryId: string): boolean => {
        return this.selectedCategoriesTree && this.selectedCategoriesTree.includes(categoryId)
      }
    }

    onToggleFolder() {
      if (this.isFolder) {
        this.isOpened = !this.isOpened
      }
    }

    onCategorySelected(categoryId: string) {
      if (categoryId !== this.selectedCategoryId) {
        this.$emit('category-selected', categoryId)
      }
    }

    onNameClick() {
      if (!this.useAnchors) {
        this.onCategorySelected(this.categoryId)
      }
    }

    get categoryCount() {
      return (categoryId: string) => {
        return this.categoriesCount[categoryId]
      }
    }

    hasItems(category: string) {
      return this.categoryCount(category) > 0
    }

    createTestId(categoryName: string): string | null {
      return this.hydrationIdle ? `catalog-list:categories-list:category-${categoryName}:li` : null
    }
  }
